import React, { useContext, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import ToggleColorMode from "../button/ToggleColorMode";
import BreadCrumb from "./BreadCrumb";
import { IoNotificationsOutline } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";
import { HiLogout } from "react-icons/hi";
import { RiUserSettingsLine } from "react-icons/ri";
import AuthContext from "../../utils/request/Authentication";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { logoutHandler } = useContext(AuthContext);

  return (
    <HStack
      className="primaryColor"
      w={"full"}
      minH={"14"}
      h={"14"}
      justify={"space-between"}
      align={"center"}
      px={"4"}
      borderBottomWidth={"thin"}
      borderColor={"#eee"}
    >
      <BreadCrumb />
      <HStack spacing={4}>
        <ToggleColorMode />
        <Icon
          as={IoNotificationsOutline}
          boxSize={"5"}
          // onClick={() => toggleColorMode()}
          cursor={"pointer"}
        />
        {/* <Menu autoSelect={false} gutter={6}>
          {({ isOpen }) => (
            <>
              <MenuButton
                // bg={"red"}
                pt={"1"}
                m={"0"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Icon
                  as={FiSettings}
                  boxSize={"5"}
                  transform={`rotate(${isOpen ? 120 : 0}deg)`}
                  transition="transform 0.5s ease"
                  cursor={"pointer"}
                />
              </MenuButton>
              <MenuList
              // border={"none"}
              // rounded={"sm"}
              // shadow={"xl"}
              // p={"0"}
              >
                <MenuItem
                  icon={<RiUserSettingsLine size={"16"} />}
                  fontSize={14}
                >
                  Миний мэдээлэл
                </MenuItem>
                <MenuItem
                  icon={<HiLogout size={"16"} />}
                  fontSize={14}
                  onClick={() => logoutHandler()}
                >
                  Гарах
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu> */}

        <Popover placement="bottom-end" isLazy>
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <IconButton
                  icon={
                    <FiSettings
                      size={"20"}
                      style={{
                        transform: `rotate(${isOpen ? 120 : 0}deg)`,
                        transition: "transform 0.5s ease",
                      }}
                    />
                  }
                  // variant="ghost"
                  bg={"transparent"}
                  size={"xs"}
                  // transform={`rotate(${isOpen ? 120 : 0}deg)`}
                  // transition="transform 0.5s ease"
                  _hover={{
                    bg: "transparent",
                  }}
                  _focus={{
                    bg: "transparent",
                  }}
                />
                {/* <Icon
                  as={FiSettings}
                  boxSize={"5"}
                  transform={`rotate(${isOpen ? 120 : 0}deg)`}
                  transition="transform 0.5s ease"
                  cursor={"pointer"}
                /> */}
              </PopoverTrigger>
              <PopoverContent w="fit-content" _focus={{ boxShadow: "none" }}>
                <PopoverArrow />
                <PopoverBody>
                  <Stack spacing={0}>
                    <Button
                      w="194px"
                      variant="ghost"
                      rightIcon={<RiUserSettingsLine />}
                      justifyContent="space-between"
                      fontWeight="normal"
                      fontSize="sm"
                      onClick={() => {
                        onClose();
                        navigate("/profile");
                      }}
                      _hover={{
                        bg: "rgba(0,0,0,.03)",
                      }}
                    >
                      Миний мэдээлэл
                    </Button>
                    <Button
                      w="194px"
                      variant="ghost"
                      rightIcon={<HiLogout />}
                      justifyContent="space-between"
                      fontWeight="normal"
                      colorScheme="red"
                      fontSize="sm"
                      onClick={() => {
                        onClose();
                        logoutHandler();
                      }}
                      _hover={{
                        bg: "rgba(255,0,0,.03)",
                      }}
                    >
                      Гарах
                    </Button>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
        <Divider h={"30px"} orientation="vertical" color={"red"} />
        <HStack>
          <Text fontWeight={"medium"}>STEVE</Text>
          <Avatar bg="blue.500" boxSize={"8"} />
        </HStack>
      </HStack>
    </HStack>
  );
};

export default Header;
