import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomInput from "../../../components/input/CustomInput";
import axios from "../../../utils/axios";
import Cookies from "js-cookie";

const CreateModal = ({
  isOpen,
  onClose,
  orgId,
  editId,
  setEditId,
  orgData,
  setTrigger,
}) => {
  const invoiceHour = parseInt(orgData?.invoiceTime.split(":")[0], 10);
  const closeHour = parseInt(orgData?.closeTime.split(":")[0], 10);
  const invoiceInt = orgData?.invoiceInterval;

  const [step, setStep] = useState(1);
  const [invoiceInterval, setInvoiceInterval] = useState(null);
  const [invoiceTime, setInvoiceTime] = useState(null);
  const [closeInterval, setCloseInterval] = useState(null);
  const [closeTime, setCloseTime] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isDisabled, setIsDisabled] = useState(null);
  const token = Cookies.get("accessToken");
  const toast = useToast();
  const id = "toast";

  useEffect(() => {
    if (orgData && editId) {
      setInvoiceInterval(parseInt(invoiceInt, 10));
      setInvoiceTime(invoiceHour);
      setCloseInterval(orgData?.closeInterval);
      setCloseTime(closeHour);
      setAmount(orgData?.unitPrice);
    }
  }, [editId]);

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);

  const resetForm = () => {
    setInvoiceInterval(null);
    setInvoiceTime(null);
    setCloseInterval(null);
    setCloseTime(null);
    setAmount(null);
    setStep(1);
    setEditId(null);
  };

  // const submitHandler = () => {
  //   // console.log(
  //   //   "submitHandler",
  //   //   invoiceInterval,
  //   //   invoiceTime,
  //   //   closeInterval,
  //   //   closeTime,
  //   //   amount
  //   // );
  //   if (editId) {
  //     setIsDisabled(true);
  //     axios
  //       .put(
  //         "/api/organization/update_time",
  //         {
  //           organizationId: editId,
  //           invoiceInterval: parseInt(invoiceInterval),
  //           invoiceTime: `${
  //             invoiceTime < 10 ? "0" + invoiceTime : invoiceTime
  //           }:00`,
  //           closeInterval: parseInt(closeInterval),
  //           closeTime: `${closeTime < 10 ? "0" + closeTime : closeTime}:00`,
  //           unitPrice: parseInt(amount),
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((result) => {
  //         // console.log(result.data);
  //         if (result.data.success) {
  //           if (!toast.isActive(id)) {
  //             toast({
  //               id,
  //               duration: 2000,
  //               position: "top",
  //               status: "success",
  //               description: "Амжилттай!",
  //             });
  //           }
  //           resetForm();
  //           onClose();
  //           setTrigger(true);
  //         }
  //         setIsDisabled(false);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         if (!toast.isActive(id)) {
  //           toast({
  //             id,
  //             duration: 2000,
  //             position: "top",
  //             status: "error",
  //             description: err.response.data.error
  //               ? err.response.data.error
  //               : "Алдаа гарлаа!",
  //           });
  //         }
  //         setIsDisabled(false);
  //       });
  //   } else {
  //     if (
  //       orgId &&
  //       invoiceInterval &&
  //       invoiceTime &&
  //       (closeInterval || closeInterval === 0) &&
  //       closeTime &&
  //       amount
  //     ) {
  //       //   console.log({
  //       //     organizationId: orgId,
  //       //     invoiceInterval: parseInt(invoiceInterval),
  //       //     invoiceTime: `${invoiceTime < 10 ? "0" + invoiceTime : invoiceTime}:00`,
  //       //     closeInterval: parseInt(closeInterval),
  //       //     closeTime: `${closeTime < 10 ? "0" + closeTime : closeTime}:00`,
  //       //     unitPrice: parseInt(amount),
  //       //   });
  //       setIsDisabled(true);
  //       axios
  //         .post(
  //           "/api/organization/set_time",
  //           {
  //             organizationId: orgId,
  //             invoiceInterval: parseInt(invoiceInterval),
  //             invoiceTime: `${
  //               invoiceTime < 10 ? "0" + invoiceTime : invoiceTime
  //             }:00`,
  //             closeInterval: parseInt(closeInterval),
  //             closeTime: `${closeTime < 10 ? "0" + closeTime : closeTime}:00`,
  //             unitPrice: parseInt(amount),
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         )
  //         .then((result) => {
  //           // console.log(result.data);
  //           if (result.data.success) {
  //             if (!toast.isActive(id)) {
  //               toast({
  //                 id,
  //                 duration: 2000,
  //                 position: "top",
  //                 status: "success",
  //                 description: "Амжилттай!",
  //               });
  //             }

  //             resetForm();
  //             onClose();
  //           }
  //           setIsDisabled(false);
  //         })
  //         .catch((err) => {
  //           // console.log(err);
  //           setIsDisabled(false);
  //           if (!toast.isActive(id)) {
  //             toast({
  //               id,
  //               duration: 2000,
  //               position: "top",
  //               status: "error",
  //               description: err.response.data.error
  //                 ? err.response.data.error
  //                 : "Алдаа гарлаа!",
  //             });
  //           }
  //         });
  //     }
  //   }
  // };
  const submitHandler = async () => {
    // Helper to format time as "HH:00"
    const formatTime = (time) => `${time < 10 ? "0" + time : time}:00`;

    // Helper to show toast
    const showToast = (status, description) => {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 2000,
          position: "top",
          status,
          description,
        });
      }
    };

    // Prepare data
    const requestData = {
      organizationId: editId || orgId,
      invoiceInterval: parseInt(invoiceInterval),
      invoiceTime: formatTime(invoiceTime),
      closeInterval: parseInt(closeInterval),
      closeTime: formatTime(closeTime),
      unitPrice: parseInt(amount),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // Perform request
    try {
      setIsDisabled(true);
      const endpoint = editId
        ? "/api/organization/update_time"
        : "/api/organization/set_time";
      const response = await axios[editId ? "put" : "post"](
        endpoint,
        requestData,
        config
      );

      if (response.data.success) {
        showToast("success", "Амжилттай!");
        resetForm();
        onClose();
        setTrigger(true);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Алдаа гарлаа!";
      showToast("error", errorMessage);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="scale"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent pt={4}>
        <ModalCloseButton
          onClick={() => {
            onClose();
            resetForm();
          }}
          _focusVisible={{ outline: "none" }}
        />
        {step === 1 && (
          <>
            <ModalBody>
              <VStack align={"flex-start"} spacing={2}>
                <Text fontWeight={"medium"} fontSize={17}>
                  Нэхэмжлэх гарах давтамж
                </Text>
                <Text fontSize={12}>
                  Хэдэн өдрийн давтамжтай нэхэмжлэх явуулах хугацааг сонгоно уу!
                </Text>
                {Array.from({ length: Math.ceil(30 / 6) }, (_, rowIndex) => (
                  <HStack key={rowIndex} space={2} w={"full"}>
                    {Array.from({ length: 6 }, (_, colIndex) => {
                      const number = rowIndex * 6 + colIndex + 1; // Adjust for 1-based index
                      if (number > 30) return null;
                      return (
                        <Button
                          key={number}
                          w={"full"}
                          h={10}
                          borderWidth={2}
                          borderColor={
                            invoiceInterval === number ? "#0066CC" : "#eee"
                          }
                          rounded={"md"}
                          bg={"#fff"}
                          cursor={"pointer"}
                          _hover={{ borderColor: "#0066CC" }}
                          onClick={() => setInvoiceInterval(number)}
                        >
                          <Text
                            fontSize={13}
                            fontWeight={"medium"}
                            color={"#000"}
                          >
                            {number}
                          </Text>
                        </Button>
                      );
                    })}
                  </HStack>
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                px={"8"}
                fontSize={13}
                onClick={handleNext}
                _focusVisible={{ outline: "none" }}
                isDisabled={!invoiceInterval}
              >
                Үргэлжлүүлэх
              </Button>
            </ModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <ModalBody>
              <VStack align={"flex-start"} spacing={2}>
                <Text fontWeight={"medium"} fontSize={17}>
                  Нэхэмжлэх үүсэх цаг
                </Text>
                <Text fontSize={12}>
                  Тухайн өдрийн хэдэн цагт нэхэмжлэх үүсгэх хугацааг сонгоно уу!
                </Text>
                <VStack space={4} w={"full"}>
                  {Array.from({ length: Math.ceil(24 / 8) }, (_, rowIndex) => (
                    <HStack key={rowIndex} w={"full"} space={2}>
                      {Array.from({ length: 8 }, (_, colIndex) => {
                        const number = rowIndex * 8 + colIndex + 1;
                        if (number > 24) return null;
                        return (
                          <Button
                            key={number}
                            w={"full"}
                            h={10}
                            borderWidth={2}
                            borderColor={
                              invoiceTime === number
                                ? "#0066CC"
                                : invoiceTime > number
                                ? "#bddeff"
                                : "#eee"
                            }
                            rounded={"md"}
                            bg={"#fff"}
                            cursor={"pointer"}
                            _hover={{ borderColor: "#0066CC" }}
                            onClick={() => setInvoiceTime(number)}
                          >
                            <Text
                              fontSize={13}
                              fontWeight={"medium"}
                              color={"#000"}
                            >
                              {number < 10 ? "0" + number : number}
                            </Text>
                          </Button>
                        );
                      })}
                    </HStack>
                  ))}
                </VStack>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                mr={3}
                px={"8"}
                fontSize={13}
                onClick={handleBack}
                _focusVisible={{ outline: "none" }}
              >
                Буцах
              </Button>
              <Button
                colorScheme="blue"
                px={"8"}
                fontSize={13}
                onClick={handleNext}
                _focusVisible={{ outline: "none" }}
                isDisabled={!invoiceTime}
              >
                Үргэлжлүүлэх
              </Button>
            </ModalFooter>
          </>
        )}
        {step === 3 && (
          <>
            <ModalBody>
              <VStack align={"flex-start"} spacing={2}>
                <Text fontWeight={"medium"} fontSize={17}>
                  Системийг хаах
                </Text>
                <Text fontSize={12}>
                  Нэхэмжлэх гарсанаас хойш хэдэн өдрийн дараа систем хаагдах
                  хугацааг сонгоно уу!
                </Text>
                {Array.from({ length: Math.ceil(16 / 8) }, (_, rowIndex) => (
                  <HStack key={rowIndex} w={"full"} space={2}>
                    {Array.from({ length: 8 }, (_, colIndex) => {
                      const number = rowIndex * 8 + colIndex;
                      if (number > 16) return null;
                      return (
                        <Button
                          key={number}
                          w={"full"}
                          h={10}
                          borderWidth={2}
                          borderColor={
                            closeInterval === number ? "#0066CC" : "#eee"
                          }
                          rounded={"md"}
                          bg={"#fff"}
                          cursor={"pointer"}
                          _hover={{ borderColor: "#0066CC" }}
                          onClick={() => setCloseInterval(number)}
                        >
                          <Text
                            fontSize={13}
                            fontWeight={"medium"}
                            color={"#000"}
                          >
                            {number}
                          </Text>
                        </Button>
                      );
                    })}
                  </HStack>
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                mr={3}
                px={"8"}
                fontSize={13}
                onClick={handleBack}
                _focusVisible={{ outline: "none" }}
              >
                Буцах
              </Button>
              <Button
                colorScheme="blue"
                px={"8"}
                fontSize={13}
                onClick={handleNext}
                _focusVisible={{ outline: "none" }}
                isDisabled={closeInterval || closeInterval === 0 ? false : true}
              >
                Үргэлжлүүлэх
              </Button>
            </ModalFooter>
          </>
        )}
        {step === 4 && (
          <>
            <ModalBody>
              <VStack align={"flex-start"} spacing={2}>
                <Text fontWeight={"medium"} fontSize={17}>
                  Системийг хаах цаг
                </Text>
                <Text fontSize={12}>
                  Нэхэмжлэх үүсэх өдрийн хэдэн цагт гаргах хугацааг сонгоно уу!
                </Text>
                {Array.from({ length: Math.ceil(24 / 8) }, (_, rowIndex) => (
                  <HStack key={rowIndex} w={"full"} space={2}>
                    {Array.from({ length: 8 }, (_, colIndex) => {
                      const number = rowIndex * 8 + colIndex + 1;
                      if (number > 24) return null;
                      return (
                        <Button
                          key={number}
                          w={"full"}
                          h={10}
                          borderWidth={2}
                          borderColor={
                            closeTime === number
                              ? "#0066CC"
                              : closeTime > number ||
                                (closeTime > number &&
                                  closeInterval === 0 &&
                                  number > invoiceTime)
                              ? "#bddeff"
                              : "#eee"
                          }
                          rounded={"md"}
                          bg={"#fff"}
                          cursor={"pointer"}
                          _hover={{ borderColor: "#0066CC" }}
                          onClick={() => setCloseTime(number)}
                          isDisabled={
                            closeInterval === 0 && invoiceTime >= number
                              ? true
                              : false
                          }
                        >
                          <Text
                            fontSize={13}
                            fontWeight={"medium"}
                            color={"#000"}
                          >
                            {number < 10 ? "0" + number : number}
                          </Text>
                        </Button>
                      );
                    })}
                  </HStack>
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                mr={3}
                px={"8"}
                fontSize={13}
                onClick={handleBack}
                _focusVisible={{ outline: "none" }}
              >
                Буцах
              </Button>
              <Button
                colorScheme="blue"
                px={"8"}
                fontSize={13}
                onClick={handleNext}
                _focusVisible={{ outline: "none" }}
                isDisabled={!closeTime}
              >
                Үргэлжлүүлэх
              </Button>
            </ModalFooter>
          </>
        )}
        {step === 5 && (
          <>
            <ModalBody>
              <VStack align={"flex-start"} spacing={2}>
                <Text fontWeight={"medium"} fontSize={17}>
                  Системийн нэгж үнэлгээ
                </Text>
                <Text fontSize={12}>
                  Нэгж ширээ болон компьютерыг үнэлэх үнэлгээг оруулна уу!
                </Text>
                <CustomInput
                  title={"Үнэ"}
                  type={"number"}
                  value={amount}
                  onChange={setAmount}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                mr={3}
                px={"8"}
                fontSize={13}
                onClick={handleBack}
                _focusVisible={{ outline: "none" }}
              >
                Буцах
              </Button>
              <Button
                colorScheme="blue"
                px={"8"}
                fontSize={13}
                onClick={submitHandler}
                _focusVisible={{ outline: "none" }}
                isDisabled={!isDisabled && amount ? false : true}
              >
                {isDisabled ? <Spinner mr={2} size="sm" /> : null}
                Дуусгах
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
