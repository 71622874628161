import axios from "axios";
import Cookies from "js-cookie";
const source = axios.CancelToken.source();
const token = Cookies.get("accessToken");
let instance;

instance = axios.create({
  // baseURL: "http://192.168.1.92:5006",
  baseURL: "https://adminvts.viot.mn:5006",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  cancelToken: source.token,
});
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
