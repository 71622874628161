import React, { useEffect, useState } from "react";
import {
  Center,
  Divider,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import axios from "../../../utils/axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import BranchTable from "./BranchTable";
import CustomPagination from "../../../components/pagination/CustomPagination";
import BackButton from "../../../components/button/BackButton";
import CreateModal from "./CreateModal";
import { BiEdit } from "react-icons/bi";

const OrganizationDetail = () => {
  const [trigger, setTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState(null);
  const location = useLocation();
  const { state } = location;
  const token = Cookies.get("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (trigger && state._id) {
      setIsLoading(true);
      let source = axios.CancelToken.source();
      let cancel = false;
      axios
        .get("/api/branch/", {
          params: { organizationId: state._id, page: currentPage },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        })
        .then((result) => {
          if (cancel) return;
          setIsLoading(false);
          // console.log("branchs --------->", result.data);
          setCurrentPage(parseInt(result.data.currentPage));
          setTotalCount(parseInt(result.data.theCount));
          setData(result.data.data);
          setOrgData(result.data.orgData);
          setTrigger(false);
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
          setTrigger(false);
        });
      return () => {
        cancel = true;
        source.cancel();
      };
    }
  }, [trigger]);

  const headerData = [
    "#",
    "Салбарын нэр",
    "Утас",
    "Компьютерын тоо",
    "Хаяг",
    "Токен",
    "Үүссэн огноо",
    "Үйлдэл",
  ];

  return (
    <VStack
      w={"full"}
      pb={"6"}
      spacing={4}
      align={"flex-start"}
      justify={"flex-start"}
    >
      <HStack w="full" align={"center"} justify={"space-between"}>
        <BackButton />
        {orgData ? null : (
          <HStack
            className="primaryColor"
            spacing={1}
            px={"6"}
            py={"2"}
            border={"1px"}
            borderColor={"#ddd"}
            onClick={() => onOpen()}
            cursor={"pointer"}
          >
            <Text fontSize={13} fontWeight={"medium"}>
              Төлбөрийн хугацаа нэмэх
            </Text>
          </HStack>
        )}
      </HStack>
      <BranchTable
        data={data}
        headerData={headerData}
        isLoading={isLoading}
        setTrigger={setTrigger}
      />
      <CustomPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTrigger={setTrigger}
        totalCount={totalCount}
      />
      {orgData && (
        <VStack
          minW={"xs"}
          bg={"#fff"}
          align={"flex-start"}
          alignSelf={"flex-end"}
          p={4}
          borderWidth={1}
          borderColor={"#eee"}
        >
          <HStack w={"full"} align={"center"} justify={"space-between"}>
            <Text color={"#000"} fontWeight={"bold"} fontSize={15}>
              Нэхэмжлэх:
            </Text>
            <Center
              onClick={() => {
                setEditId(state._id);
                onOpen();
              }}
              cursor={"pointer"}
            >
              <BiEdit size={18} color="#0066CC" />
            </Center>
          </HStack>
          <Divider />
          <HStack w={"full"} align={"center"} justify={"space-between"}>
            <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
              Нэхэмжлэхийн давтамж:
            </Text>
            <Text color={"#000"} fontWeight={"medium"} fontSize={13}>
              {orgData.invoiceInterval + " өдөр"}
            </Text>
          </HStack>
          <HStack w={"full"} align={"center"} justify={"space-between"}>
            <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
              Үүсэх цаг:
            </Text>
            <Text color={"#000"} fontWeight={"medium"} fontSize={13}>
              {orgData.invoiceTime + " цагт"}
            </Text>
          </HStack>
          <Divider />
          <HStack w={"full"} align={"center"} justify={"space-between"}>
            <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
              Хаах өдөр:
            </Text>
            <Text color={"#000"} fontWeight={"medium"} fontSize={13}>
              {orgData.closeInterval + " өдөр"}
            </Text>
          </HStack>
          <HStack w={"full"} align={"center"} justify={"space-between"}>
            <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
              Хаагдах цаг:
            </Text>
            <Text color={"#000"} fontWeight={"medium"} fontSize={13}>
              {orgData.closeTime + " цагт"}
            </Text>
          </HStack>
          <Divider />
          <HStack w={"full"} align={"center"} justify={"space-between"}>
            <Text color={"#000"} fontWeight={"normal"} fontSize={13}>
              Нэгж үнэ:
            </Text>
            <Text color={"#000"} fontWeight={"medium"} fontSize={13}>
              {orgData.unitPrice.toLocaleString() + "₮"}
            </Text>
          </HStack>
          {/* <Button w={"full"} fontSize={13} alignSelf={"center"}>
        Засах
      </Button> */}
        </VStack>
      )}

      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        orgId={state._id}
        editId={editId}
        setEditId={setEditId}
        orgData={orgData}
        setTrigger={setTrigger}
      />
    </VStack>
  );
};

export default OrganizationDetail;
